<template>
  <div>
    <div class="planner d-flex">
      <div class="planner-left">
        <div class="planner-left-header">
          <template>
            <div class="btn-group cards-view-button cards-view-button-center" role="group">
              <button type="button" :class="{'btn-primary': isGrid}" class="btn">Grid View</button>
              <button type="button" :class="{'btn-primary': isTimeline}" disabled="disable" class="btn">Timeline View</button>
            </div>
          </template>
        </div>
        <div class="planner-left-body">
          <div class="planner-dividing-line"></div>
          <div class="planner-left-body-elem" style="min-height: 120px; padding-top: 20px">
            <div class="planner-left-body-elem-title ">Total Job Arriving</div>
          </div>
          <div class="planner-left-body-elem" v-for="itm in categoriesTotals" :style="settedStyles.includes(itm.id) ? getStyle(itm): null">
            <div class="planner-left-body-elem-title d-flex">
              <i v-if="!openedCategories.includes(itm.id)"
                 class='bx bx-chevron-right active'
                 style="cursor: pointer"
                 @click="openCategory(itm)"
              ></i>
              <i v-else
                 class='bx bx-chevron-down active'
                 style="cursor: pointer"
                 @click="openCategory(itm)"
              ></i>

              {{ itm.name }}
            </div>
            <div class="planner-left-body-elem-subtitle">
              <div>Target New Jobs {{itm.eventsCount}}</div>
              <div>Current: {{ getEventsOnSelectedPeriod(itm).length }}</div>
            </div>
          </div>

          <div class="planner-left-body-elem" :style="settedStyles.includes('total') ? getStyle(null, true): null">
            <div class="planner-left-body-elem-title d-flex">
              <i v-if="!openedCategories.includes('total')"
                 class='bx bx-chevron-right active'
                 style="cursor: pointer"
                 @click="openCategory(null, true)"
              ></i>
              <i v-else
                 class='bx bx-chevron-down active'
                 style="cursor: pointer"
                 @click="openCategory(null, true)"
              ></i>
              Total Jobs Booked
            </div>
            <div class="planner-left-body-elem-subtitle">
              <div>Target Jobs {{computedBookedTargetJobs}}</div>
              <div>Current: {{ computedBookedCurrent }}</div>
            </div>
          </div>
          <div class="planner-dividing-line"></div>
        </div>
        <div class="planner-left-footer">
          <div class="planner-left-footer-elem">
            <div class="planner-left-footer-elem-title">Total Booked Sales</div>
            <div class="planner-left-footer-elem-subtitle">Current: {{getCurrentWeekTotal() | formatMoney}} <span>Target: {{computedDayTotal | formatMoney}}</span></div>
          </div>
          <div class="planner-left-footer-elem" style="height: 60px; padding-top: 10px">
            <div class="planner-left-footer-elem-title">Total Jobs Completing</div>
            <div class="planner-left-footer-elem-subtitle">Scheduled: {{computedScheduledTotal }} <span>Current: {{getCompletedDaysTotal()}}</span></div>
          </div>
          <div class="planner-left-footer-elem">
            <div class="planner-left-footer-elem-title">Total Completing Sales</div>
            <div class="planner-left-footer-elem-subtitle">Current: {{getCurrentDayForCompletedTotal() | formatMoney}} <span>Target: {{computedDayTotal | formatMoney}}</span></div>
          </div>
        </div>
      </div>
      <div class="planner-right" @scroll="scrollPlannerRight">
        <div class="planner-right-header d-flex">
          <div class="planner-right-header-day" v-for="day in currentWeekDates" :style="{ width: 100 / currentWeekDates.length + '%'}" @click="selectDay(day)" :class="{'active-day': computedSelectedDates.includes(day && day.dayFormat ? day.dayFormat: null)}">
            <div class="planner-right-header-day-name">{{ day.weekD }}</div>
            <div class="planner-right-header-day-num">{{ day.date }}</div>
          </div>
        </div>
        <div class="d-flex table-visible-first" style="width: 100%;">
          <div class="planner-right-body" v-for="day in currentWeekDates" :style="{ width: 100 / currentWeekDates.length + '%'}">
            <div class="planner-dividing-line"></div>
            <div class="planner-right-body-item" style="min-height: 120px; padding-top: 0">
              <div class="planner-right-body-item-num">6</div>
              <div class="planner-right-body-item-elem bold">{{getBookedTotal(day)}} (Booked)</div>
              <div class="planner-right-body-item-elem bold" :class="{'yellow': isYellowArrivedTotal(day)}">{{getArrivedToTotal(day)}} (To Arrive)</div>
              <div class="planner-right-body-item-elem bold" :class="{'orange': getCarryTotal(day)}">{{getCarryTotal(day)}} (Carry Over)</div>
              <div class="planner-right-body-item-elem bold">{{getOnSiteTotal(day)}} (On-Site)</div>
            </div>
            <div class="planner-right-body-item" v-for="itm in categoriesTotals" :class="[getClass(itm)]">
              <div class="planner-right-body-item-elem">{{getBooked(day, itm)}} (Booked)</div>
              <div class="planner-right-body-item-elem" :class="{'yellow': isYellowArrived(day, itm)}">{{getArrivedTo(day, itm)}} (To Arrive)</div>
              <div class="planner-right-body-item-elem" :class="{'orange': getCarryOver(day, itm)}">{{getCarryOver(day, itm)}} (Carry Over)</div>
              <div class="planner-right-body-item-elem">{{getOnSite(day, itm)}} (On-Site)</div>
              <div v-if="openedCategories.includes(itm.id) && !_isEmpty(getEventsOnSelectedPeriod(itm))" class="planner-right-body-table">
                <div class="planner-right-body-table-header d-flex">
                  <div style="width: 90px">Estimate</div>
                  <div style="width: 100px">Owner</div>
                  <div style="width: 100px">Insurer</div>
                  <div style="width: 50px">Auth</div>
                  <div style="width: 150px">Status</div>
                  <div style="width: 100px">Rego</div>
                  <div style="width: 150px">Vehicle</div>
                  <div style="width: 100px">Booked</div>
                  <div style="width: 100px">Completion</div>
                  <div style="width: 100px">Trackable</div>
                </div>
                <div class="planner-right-body-table-body d-flex" v-for="event in getEventsOnSelectedPeriod(itm)">
                  <div style="width: 90px; cursor:pointer" @click="goToEstimate(event)">{{event && event.card && event.card.number ? event.card.number : ''}}</div>
                  <div style="width: 100px">{{event && event.card && event.card.vehicleOwner ? event.card.vehicleOwner : ''}}</div>
                  <div style="width: 100px">{{event && event.card && event.card.insurer ? event.card.insurer : ''}}</div>
                  <div style="width: 50px">{{event && event.estimate && event.estimate.isAuth ? 'Yes' : 'No'}}</div>
                  <div style="width: 150px">{{getEventStatus(event, itm, day)}}</div>
                  <div style="width: 100px">{{event && event.card && event.card.vehicleRego ? event.card.vehicleRego : ''}}</div>
                  <div style="width: 150px">{{event && event.card ? event.card.vehicleMake + (event.card.vehicleMake && event.card.vehicleModel ? ' ' : '') + event.card.vehicleModel : ''}}</div>
                  <div style="width: 100px">{{event.dateStart | formatDate}}</div>
                  <div style="width: 100px">{{event.dateEnd | formatDate}}</div>
                  <div style="width: 100px"></div>
                </div>
              </div>
            </div>

            <div class="planner-right-body-item total-category">
              <div class="planner-right-body-item-elem"></div>
              <div class="planner-right-body-item-elem"></div>
              <div class="planner-right-body-item-elem orange"></div>
              <div class="planner-right-body-item-elem"></div>
              <div v-if="openedCategories.includes('total') && !_isEmpty(computedBookedCurrentEvents)" class="planner-right-body-table">
                <div class="planner-right-body-table-header d-flex">
                  <div style="width: 90px">Estimate</div>
                  <div style="width: 100px">Owner</div>
                  <div style="width: 100px">Insurer</div>
                  <div style="width: 50px">Auth</div>
                  <div style="width: 150px">Status</div>
                  <div style="width: 100px">Rego</div>
                  <div style="width: 150px">Vehicle</div>
                  <div style="width: 100px">Booked</div>
                  <div style="width: 100px">Completion</div>
                  <div style="width: 100px">Trackable</div>
                </div>
                <div class="planner-right-body-table-body d-flex" v-for="event in computedBookedCurrentEvents">
                  <div style="width: 90px; cursor:pointer;" @click="goToEstimate(event)">{{event && event.card && event.card.number ? event.card.number : ''}}</div>
                  <div style="width: 100px">{{event && event.card && event.card.vehicleOwner ? event.card.vehicleOwner : ''}}</div>
                  <div style="width: 100px">{{event && event.card && event.card.insurer ? event.card.insurer : ''}}</div>
                  <div style="width: 50px">{{event && event.estimate && event.estimate.isAuth ? 'Yes' : 'No'}}</div>
                  <div style="width: 150px">{{getEventStatus(event, day)}}</div>
                  <div style="width: 100px">{{event && event.card && event.card.vehicleRego ? event.card.vehicleRego : ''}}</div>
                  <div style="width: 150px">{{event && event.card ? event.card.vehicleMake + (event.card.vehicleMake && event.card.vehicleModel ? ' ' : '') + event.card.vehicleModel : ''}}</div>
                  <div style="width: 100px">{{event.dateStart | formatDate}}</div>
                  <div style="width: 100px">{{event.dateEnd | formatDate}}</div>
                  <div style="width: 100px"></div>
                </div>
              </div>
            </div>
            <div class="planner-dividing-line"></div>
          </div>
        </div>
        <div class="planner-right-footer d-flex">
          <div class="planner-right-footer-day" v-for="day in currentWeekDates" :style="{ width: 100 / currentWeekDates.length + '%'}">
            <div class="planner-right-footer-elem">
              <div class="planner-right-footer-elem-num">{{getCurrentDayTotal(day.dayFormat) | formatMoney}}</div>
            </div>
            <div class="planner-right-footer-elem" style="display: flex; align-items: flex-end; position: relative; height: 60px">
              <div class="planner-right-footer-elem-num-circle" :class="{'blue': getCompletedDays(day) <= computedScheduledFoDay, 'red': getCompletedDays(day) > computedScheduledFoDay}">{{getCompletedDays(day)}}</div>
              <div class="planner-right-footer-elem-num" style="text-align: left">{{computedScheduledFoDay}}</div>
            </div>
            <div class="planner-right-footer-elem">
              <div class="planner-right-footer-elem-num">{{getCurrentDayForCompleted(day) | formatMoney}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "planner-calendar",
  data() {
    return{
      scrollTablePosition: 0,
      isTimeline: false,
      isGrid: true,
      selectedDates: [],
      openedCategories: [],
      settedStyles: [],
    }
  },
  computed: {
    computedScheduledTotal() {
      return _.sumBy(this.categoriesTotals, (itm) => { return Number(itm.qty)})
    },
    computedDayTotal() {
      let count = _.filter(this.settings.days, (d) => {
        return d
      }).length
      return Number(this.settings.targets.job) / count
    },
    computedScheduledFoDay() {
      let count = _.filter(this.settings.days, (d) => {
        return d
      }).length
      return this.computedScheduledTotal / count
    },
    computedBookedTargetJobs() {
      return _.sumBy(this.categoriesTotals, (itm) => { return Number(itm.eventsCount)})
    },
    computedBookedCurrent() {
      let a = 0;
      _.forEach(this.categoriesTotals, (c) => {
        a += this.getEventsOnSelectedPeriod(c).length
      })
      return a
    },
    computedBookedCurrentEvents() {
      let a = [];
      _.forEach(this.categoriesTotals, (c) => {
        a = _.concat(a, this.getEventsOnSelectedPeriod(c))
      })
      a = _.uniqBy(a, 'id')
      return a
    },
    computedSelectedDates() {
      if (!_.isEmpty(this.selectedDates)) {
        let r = _.cloneDeep(this.selectedDates);
        return _.orderBy(r, [(itm) => new Date(itm).getTime()], 'asc')
      } else {
        let dates = this.currentWeekDates.slice(0, this.currentWeekDates.length / 2)
        return _.map(dates, (d) => {
          return d.dayFormat
        })
      }
    }
  },
  props: {
    currentWeekDates: {
      type: Array,
    },
    categoriesTotals: {
      type: Array,
    },
    settings: {
      type: Object,
    },
    ceStatuses: {
      type: Array,
    },
    events: {
      type: Array
    }
  },
  methods: {
    scrollPlannerRight(evt, el){
      this.scrollTablePosition = evt.srcElement.scrollTop
      document.querySelector('.planner-left').scrollTop = this.scrollTablePosition
    },
    getCurrentWeekTotal() {
      let a = 0;
      _.forEach(this.currentWeekDates, (d) => {
        a += this.getCurrentDayTotal(d.dayFormat)
      })
      return a
    },
    getCurrentDayTotal(d) {
      let a = 0
      let evts = _.filter(this.events, (e) => {
        return dayjs(d).isBetween( dayjs(e.dateStart), dayjs(e.dateEnd), null, '[]')
      })
      _.forEach(evts, (evt) => {
        let estimate = evt.estimate
        if (estimate && estimate.amountJob && Number(estimate.amountJob) && this.getDayAppr(evt)) {
          a += Number(estimate.amountJob) / this.getDayAppr(evt)
        }
      })
      return a
    },
    getCurrentDayForCompletedTotal() {
      let a = 0;
      _.forEach(this.currentWeekDates, (d) => {
        a += this.getCurrentDayForCompleted(d)
      })
      return a
    },
    getCurrentDayForCompleted(d) {
      let a = 0
      let evts = _.filter(this.events, (e) => {
        return dayjs(d?.dayFormat).isBetween( e.dateStart, e.dateEnd, null, '[]') && e?.dateStart == d.dayFormat
      })

      _.forEach(evts, (evt) => {
        let estimate = evt.estimate
        if (estimate && estimate.amountJob && Number(estimate.amountJob) && this.getDayAppr(evt)) {
          a += Number(estimate.amountJob) / this.getDayAppr(evt)
        }
      })
      return a
    },

    getDayAppr(event) {
      if (event?.estimate?.productionTime?.daysCount) {
        return Number(event.estimate.productionTime.daysCount)
      }
      if (event?.card?.daysCount) {
        return Number(event.card.daysCount)
      }

      return 0
    },
    goToEstimate(itm) {
      if (itm?.estimate?.estimateId) {
        this.$router.push('/estimates/view/' + itm.estimate.estimateId)
      }
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    getEventStatus(e, cat, day) {
      // if (e.dateStart == day.dayFormat) {
      //   return 'Booked'
      // }
      // if (new Date(e.dateStart).getTime() < new Date(this.currentWeekDates[0]?.dayFormat).getTime()) {
      //   return 'Carry Over'
      // }
      // if (e?.card?.arrivedDate == day.dayFormat) {
      //   return 'To Arrive'
      // }
      return _.find(this.ceStatuses, (s) => {
        return Number(e?.card?.statusId) == Number(s.ce_status_id)
      })?.name
    },
    openCategory(itm, isTotal = false) {
      let id = isTotal ? 'total' : itm.id
      this.$nextTick(() => {
        if (_.includes(this.settedStyles, id)) {
          this.settedStyles.splice(this.settedStyles.indexOf(id), 1)
        }
        if (_.includes(this.openedCategories, id)) {
          this.openedCategories.splice(this.openedCategories.indexOf(id), 1)
        } else {
          this.openedCategories.push(id)
        }
        setTimeout(() => {
          this.settedStyles.push(id)
        }, 0)
      })
    },
    getClass(v) {
      return v.id + '-category'
    },
    getStyle(v, isTotal = false) {
      let id = isTotal ? 'total' : v.id
      if (_.includes(this.openedCategories, id)) {
        let els = document.getElementsByClassName(id + '-category')
        if (els) {
          return {height: els[0].offsetHeight + 'px'}
        }
      } else {
        return {height: 'auto'}
      }
    },
    getEventsOnSelectedPeriod(itm) {
      if (itm.events) {
        return _.filter(itm.events, (ev) => {
          let s = this.computedSelectedDates[0]
          let e = this.computedSelectedDates[this.selectedDates.length - 1]
          return (dayjs(ev.dateStart).isBetween( s,  e, null, '[]')
              || dayjs(ev.dateEnd).isBetween( s,  e, null, '[]')
              || dayjs(s).isBetween( ev.dateStart, ev.dateEnd, null, '[]'))
        })
      }
      return []
    },
    selectDay(d) {
      let date = d?.dayFormat;
      if (date) {
        if (_.includes(this.selectedDates, date)) {
          this.selectedDates.splice(this.selectedDates.indexOf(date), 1)
        } else {
          this.selectedDates.push(date)
        }
        let v = _.cloneDeep(this.settedStyles)
        this.settedStyles.splice(0, this.settedStyles.length)
        setTimeout(() => {
          this.settedStyles.push(...v)
        }, 0)
      }
    },
    clearSelected() {
      this.selectedDates.splice(0, this.selectedDates.length)
      this.openedCategories.splice(0, this.openedCategories.length)
    },
    getCarryOver(d, cat) {
      return _.filter(cat.events, (e) => {
        return dayjs(d?.dayFormat).isBetween( e.dateStart, e.dateEnd, null, '[]') &&  new Date(e.dateStart).getTime() < new Date(this.currentWeekDates[0]?.dayFormat).getTime()
      }).length
    },
    getCarryTotal(d) {
      let r = 0;
      _.forEach(this.categoriesTotals, (c) => {
        r += this.getCarryOver(d, c)
      })
      return r
    },
    getBooked(d, cat) {
      return _.filter(cat.events, (e) => {
        return e.dateStart == d?.dayFormat
      }).length
    },
    getBookedTotal(d) {
      let r = 0;
      _.forEach(this.categoriesTotals, (c) => {
        r += this.getBooked(d, c)
      })
      return r
    },

    getCompletedDays(d) {
      return _.filter(this.events, (e) => {
        return dayjs(d?.dayFormat).isBetween( e.dateStart, e.dateEnd, null, '[]') && e?.dateStart == d.dayFormat
      }).length
    },
    getCompletedDaysTotal() {
      let a = 0;
      _.forEach(this.currentWeekDates, (d) => {
        a += this.getCompletedDays(d)
      })
      return a
    },
    getArrivedTo(d, cat) {
      return _.filter(cat.events, (e) => {
        return dayjs(d?.dayFormat).isBetween( e.dateStart, e.dateEnd, null, '[]') && e?.card?.arrivedDate &&  e?.card?.arrivedDate == d.dayFormat
      }).length
    },
    getArrivedToTotal(d) {
      let r = 0;
      _.forEach(this.categoriesTotals, (c) => {
        r += this.getArrivedTo(d, c)
      })
      return r
    },

    isYellowArrived(d, cat) {
      return  _.some(cat.events, (e) => {
        return dayjs(d?.dayFormat).isBetween( e.dateStart, e.dateEnd, null, '[]') && e?.card?.arrivedDate && e?.card?.arrivedDate != e.dateStart
      })
    },
    isYellowArrivedTotal(d) {
      return _.some(this.categoriesTotals, (c) => {
        return this.isYellowArrived(d, c)
      })
    },

    getOnSite(d, cat) {
      return this.getBooked(d, cat) + this.getCarryOver(d, cat)
    },
    getOnSiteTotal(d) {
      let r = 0;
      _.forEach(this.categoriesTotals, (c) => {
        r += this.getOnSite(d, c)
      })
      return r
    },
  }
}
</script>

<style scoped>
.planner{
  overflow-y: auto;
  overflow-x: hidden;
  overflow: visible;
  max-height: calc(100vh - 310px);
  scrollbar-width: thin;
}
.active-day {
  background-color: rgba(94, 121, 255, 0.1);
  /*color: #82A2DC !important;*/
}
.planner{
  width: 100%;
  margin: 20px 10px 0 10px;
}
.planner-left-header{
  position: sticky;
  top: 0;
  background: white;
  z-index: 11;
  height: 80px;
  border: 1px solid rgba(27, 30, 56, 0.25);
}
.planner-right-header{
  height: 80px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 11;
}
.planner-left{
  width: 20%;
  min-width: 300px;
  overflow: hidden;
  margin-bottom: 11px;
  max-height: calc(100vh - 310px) !important;
}
.planner-right{
  width: 80%;
  overflow-x: auto;
  scrollbar-width: thin;
}

.cards-view-button-center .btn{
  margin: 10px 5px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px !important;
}
.cards-view-button-center .btn.btn-primary{
  background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
}
.planner-right-header{
}
.planner-right-header-day{
  min-width: 110px;
  text-align: center;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-left: 0;
  font-weight: 700;
  cursor: pointer;
}
.planner-right-header-day-name{
  margin-top: 10px;
  font-size: 16px;
}
.planner-right-header-day-num{
  margin-top: 5px;
  font-size: 16px;
}
.planner-dividing-line{
  display: block;
  height: 10px;
  background-color: #d7d7d7;
}
.planner-right-footer{
}
.planner-right-footer-day{
  min-width: 110px;
}
.planner-left-body{
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-bottom: 0;
}
.planner-right-body{
  min-width: 110px;
  border-top: 1px solid rgba(27, 30, 56, 0.25);
}

.planner-left-body-elem{
  position: relative;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
}
.planner-left-footer-elem{
  padding: 0 10px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-top: 0;
}
.planner-right-footer-elem{
  padding: 0 10px;
  height: 45px;
  min-width: 110px;
  border-right: 1px solid rgba(27, 30, 56, 0.25);
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
}
.planner-right-body-item{
  min-width: 110px;
  overflow: hidden;
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
  border-right: 1px solid rgba(27, 30, 56, 0.25);
  padding: 5px 10px;
  min-height: 90px;
}
.planner-right-body-item:first-child{
  padding-bottom: 10px;
  padding-top: 0;
}
.planner-right-body-item-num{
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}
.planner-left-body-elem-subtitle{
  display: flex;
}
.planner-left-body-elem-subtitle div{
  width: 50%;
}
.planner-left-body-elem-subtitle div:last-child {
  flex-grow: 1;
  text-align: center;
}
.planner-right-body-item-elem{
  font-size: 12px;
}
.planner-right-body-item-elem:first-child{
  font-weight: 700;
}
.planner-right-body-item-elem.bold{
  font-weight: 700;
}
.planner-right-body-item-elem.orange{
  color: #ec823a;
}
.planner-right-body-item-elem.yellow{
  color: #fdc10c;
}
.planner-left-body-elem-title{
  font-weight: 700;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.planner-left-body-elem-title i{
  font-size: 22px;
  position: relative;
  bottom: 1px;
  color: blue;
  border-radius: 3px;
  display: none;
  margin-right: 3px;
}
.planner-left-body-elem-title i.active{
  display: block;
}
.planner-left-body-elem-title i:hover{
  background-color: #f1f1f1;
}
.planner-left-footer-elem-title{
  font-weight: 700;
  margin-bottom: 5px;
}
.planner-left-footer-elem-subtitle span{
  margin-left: 30px;
}
.planner-right-footer-elem-num{
  text-align: center;
}
.planner-right-footer-elem-num-circle{
  position: absolute;
  top: 0;
  height: 27px;
  width: 27px;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  border-radius: 100px;
}
.planner-right-footer-elem-num-circle.blue{
  border: 2px #5d78fd solid;
  color: #5d78fd;
}
.planner-right-footer-elem-num-circle.red{
  border: 2px #fd0000 solid;
  color: #fd0000;
}

.planner-right-body-table-header{
  background-color: #d7d7d7;
  border: 1px solid rgba(27, 30, 56, 0.25);
}
.planner-right-body-table-header div{
  padding: 7px 10px;
  font-weight: 700;
}
.planner-right-body-table-body div:first-child{
  color: #5d78fd;
  font-weight: 800;
}
.planner-right-body-table-body{
  background-color: #ffffff;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-top: 0;
}
.planner-right-body-table-body div{
  padding: 0 10px;
  white-space: nowrap;
}
.planner-right-body-table{
  width: 90px;
  opacity: 0;
}
.table-visible-first div:first-child .planner-right-body-item .planner-right-body-table{
  display: inline-block;
  width: auto;
  position: relative;
  z-index: 10;
  opacity: 1;
  background-color: red !important;
}
.table-visible-first div:first-child .planner-right-body-item{
  overflow: visible;
}
</style>
