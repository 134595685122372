import _ from "lodash";

export const Mutations = {
  loadPromos(state, ui) {
    let oldPromo = _.find(state.promos, function (promo) {
      return Number(promo.id) == Number(ui.id);
    });
    if (_.isEmpty(oldPromo)) {
      state.promos.push(ui);
    }
  },
  filterPromos(state, ui) {
    _.forEach(state.promos, (promo) => {
      if (!promo) {
        return
      }
      let p = _.find(ui, (pr) => {
        return Number(pr.id) == Number(promo.id);
      });
      if (!p) {
        let removedPromo = _.find(state.promos, function (pr) {
          return Number(pr.id) == Number(promo.id);
        });
        let index = state.promos.indexOf(removedPromo);
        state.promos.splice(index, 1);
      }
    })
  },
  removePromo(state, ui) {
    let removedPromo = _.find(state.promos, function (promo) {
      return Number(promo.id) == Number(ui.promoId);
    });
    let index = state.promos.indexOf(removedPromo);
    state.promos.splice(index, 1);
  },
  loadTeam(state, ui) {
    state.team = ui;
  },
  loadNews(state, ui) {
    state.news = ui;
  },
  loadTasks(state, ui) {
    let data = _.union(ui, state.tasks);
    let res = _.uniqWith(data, function (first, second) {
      return (first.id != second.id) ? false : true;
    });
    res = _.map(res, (i) => {
      i.id = Number(i.id);
      return i;
    });
    state.tasks = res;
  },
  loadComments(state, ui) {
    let data = _.union(ui, state.comments);
    let res = _.uniqWith(data, function (first, second) {
      return (first.id != second.id) ? false : true;
    });
    res = _.map(res, (i) => {
      i.id = Number(i.id);
      return i;
    });
    state.comments = res;
  },
  loadEmails(state, ui) {
    state.emails = ui;  //changed by #321 task
    // return;
    // let data = _.union(ui, state.emails);
    // let res = _.uniqWith(data, function (first, second) {
    //   return (first.id != second.id) ? false : true;
    // });
    // res = _.map(res, (i) => {
    //   i.id = Number(i.id);
    //   return i;
    // });
    // state.emails = res;
  },
  loadSms(state, ui) {
    state.sms = ui;
  },
  loadInsurers(state, ui) {
    state.insurers = ui;
  },
  dashboardSetShowCountTasks(state, ui) {
    state.showCountTasks = ui;
  },
  dashboardSetShowCountCards(state, ui) {
    state.showCountCards = ui;
  },
  dashboardSetShowCountComments(state, ui) {
    state.showCountComments = ui;
  },
  dashboardSetShowCountEmails(state, ui) {
    state.showCountEmails = ui;
  },
  dashboardSetShowCountSms(state, ui) {
    state.showCountSms = ui;
  },
  dashboardSetShowOldComments(state, ui) {
    state.showOldComments = ui;
  },
  dashboardSetShowOldEmails(state, ui) {
    state.showOldEmails = ui;
  },
  dashboardSetShowOldTasks(state, ui) {
    state.showOldTasks = ui;
  },

  dashboardSetIsShowLastComments(state, ui) {
    state.isShowLastComments = ui;
  },
  dashboardSetIsShowLastEmails(state, ui) {
    state.isShowLastEmails = ui;
  },
  dashboardSetIsShowLastTasks(state, ui) {
    state.isShowLastTasks = ui;
  },
  deleteTask(state, id){
    let task = _.find(state.tasks, function(t) {
      return t.id == id;
    });
    let index = state.tasks.indexOf(task);
    state.tasks.splice(index, 1);
  },
  deleteEmail(state, id) {
    let email = _.find(state.emails, function(e) {
      return e.activityId == id;
    });
    let index = state.emails.indexOf(email);
    console.log('remove', email, index, id)
    state.emails.splice(index, 1);
  },
  deleteComment(state, id) {
    let comment = _.find(state.comments, function(c) {
      return c.task.id == id;
    });
    let index = state.comments.indexOf(comment);
    state.comments.splice(index, 1);
  }
}
