import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
  showCountTasks: 5,
  showCountEmails: 5,
  showCountSms: 5,
  showCountCards: 5,
  showCountComments: 5,
  showOldTasks: false,
  showOldComments: false,
  showOldEmails: true,
  isShowLastTasks: false,
  isShowLastComments: false,
  isShowLastEmails: false,
  promos: [],
  team: [],
  news: [],
  tasks: {},
  emails: {},
  sms: [],
  insurers: [],
  comments: {},
};

export default {
  namespaced: true,
  mutations: Mutations,
  actions: Actions,
  state,
  getters: Getters,
};
