<template>
  <div class="d-flex flex-column work-center-main areaTouch">
    <div class="d-flex flex-row justify-content-between">
        <new-card></new-card>

      <div class="tabs-nav-right-search tabs-nav-right-search-font-weight" v-if="!isMobile" style="min-width: 290px !important">
        <search-filter
            type="estimates"
            class="search-filter"
            v-model="searchFilter"
            :limitLength="50"
            :filterValue="searchFilter"
            @selectAdditional="redirectToCardEdit"
            @onEnter="onEnter"
            :placeholder="'Search'"
            :additionalMenu="additionalMenu"
            :debug="false"
            height="41px"
            aria-expanded="false"
            data-toggle="dropdown"></search-filter>
      </div>

    </div>
    <div>
      <div v-if="!isDisplayBoardUser" class="dashboard-page">
        <div class="line-divider-dashboard"></div>
        <div class="white-bg row dashboard-header-border">
          <div class="col-12 col-md-12 col-lg-4 p-0">
            <div class="card p-1">
              <div class="uppercase dashboard-header pl-1">
                <strong>{{headerForTask}}</strong>
              </div>
              <div class="card-block dashboard-body dashboard-body-additional-block today-block">
                <tabs :options="{ useUrlFragment: false, isOrdered:true }" @changed="changedTab" ref="dashboard-tasks-tabs">
                  <tab name="Tasks" id="dashboard-tasks-tab-tasks" :suffix="tasksSuffix">
                    <div v-if="(tasks && tasks.length > 0)">
                      <task-summary v-for="(task, ti) in computedTasks" v-bind:key="ti" v-show="ti < showCountTasks" :task="task"></task-summary>
                      <div class="navbar-nav nav dropdown dropdown-options show inline float-right mr-0">
                    <span
                        ref="sort-dropdown"
                        class="btn waves-effect waves-light ex-options-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true">Show {{ showCountTasks }}
                      <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);">
                          <i class="fa fa-chevron-down pull-right"></i>
                      </span>
                    </span>
                        <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountTasks !== 5"  @click="optionClicked(5, 'task')">Show 5</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountTasks !== 10" @click="optionClicked(10, 'task')">Show 10</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountTasks !== 20" @click="optionClicked(20, 'task')">Show 20</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountTasks !== 50" @click="optionClicked(50, 'task')">Show 50</a>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="!showOldTasks" style="text-align: center; padding-top: 15px">
                      No new tasks today
                      <div style="margin-top: 25px;">
                        <button class="btn btn-secondary btn-border-theme" @click="showOldTasks = true">View Previous</button>
                      </div>
                    </div>
                    <div class="no-notices-block" v-else>
                      No tasks
                    </div>
                  </tab>
                  <tab name="Comments" id="dashboard-tasks-tab-comments" :suffix="commentSuffix">
                    <div v-if="(comments && comments.length > 0)">
                      <comment-summary v-for="(comment, ci) in computedComments" v-bind:key="ci" v-show="ci < showCountComments" :comment="comment"></comment-summary>
                      <div class="navbar-nav nav dropdown dropdown-options show inline float-right mr-0">
                    <span
                        ref="sort-dropdown"
                        class="btn waves-effect waves-light ex-options-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true">Show {{ showCountComments }}
                      <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);">
                          <i class="fa fa-chevron-down pull-right"></i>
                      </span>
                    </span>
                        <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountComments !== 5"  @click="optionClicked(5, 'comment')">Show 5</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountComments !== 10" @click="optionClicked(10, 'comment')">Show 10</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountComments !== 20" @click="optionClicked(20, 'comment')">Show 20</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountComments !== 50" @click="optionClicked(50, 'comment')">Show 50</a>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="!showOldComments" style="text-align: center; padding-top: 15px;">
                      No new comments today
                      <div style="margin-top: 25px;">
                        <button class="btn btn-secondary btn-border-theme" @click="showOldComments = true">View Previous</button>
                      </div>
                    </div>
                    <div class="no-notices-block" v-else>
                      No comments
                    </div>
                  </tab>
                  <tab name="Cards" id="dashboard-tasks-tab-cards" :suffix="cardsSuffix">
                    <div v-if="(getterCards && getterCards.length > 0)">
                      <cards-summary v-for="(card, ci) in computedCards" v-show="ci < showCountCards" :key="ci" :card="card"></cards-summary>
                      <div class="navbar-nav nav dropdown dropdown-options show inline float-right mr-0">
                    <span
                        ref="sort-dropdown"
                        class="btn waves-effect waves-light ex-options-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true">Show {{ showCountCards }}
                      <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);">
                          <i class="fa fa-chevron-down pull-right"></i>
                      </span>
                    </span>
                        <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountCards !== 5" @click="showCountCards = 5">Show 5</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountCards !== 10" @click="showCountCards = 10">Show 10</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountCards !== 20" @click="showCountCards = 20">Show 20</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountCards !== 50" @click="showCountCards = 50">Show 50</a>
                        </div>
                      </div>
                    </div>
                    <div v-else class="no-notices-block">
                      No cards
                    </div>
                  </tab>
                  <template slot="nav-item-right">
                    <div class="tabs-nav-right-search">
                      <b-form-input v-model="filter"
                                    type="text"
                                    class="search-input"
                                    placeholder="Search..."
                                    debounce="500"/>
                    </div>
                  </template>
                </tabs>
              </div>
            </div>
          </div>
          <div v-if="isShowEmails" class="col-12 col-md-12 col-lg-4 p-0">
            <div class="card p-1">
              <div class="uppercase dashboard-header pl-1">
                <strong>{{ headerForEmail }}</strong>
              </div>
              <div class="card-block emails-block dashboard-body dashboard-body-additional-block message-block">
                <tabs :options="{ useUrlFragment: false, isOrdered:true }"  ref="dashboard-tasks-tabs">
                  <tab name="Emails" id="dashboard-tasks-tab-emails" :suffix="emailsSuffix">
                    <div v-if="(emails && emails.length > 0)" class="">
                      <email-summary v-for="(email, ei) in computedEmails" v-bind:key="ei" :email="email" v-show="ei < showCountEmails" />
                      <div class="navbar-nav nav dropdown dropdown-options show inline float-right mr-0">
                        <span
                            ref="sort-dropdown"
                            class="btn waves-effect waves-light ex-options-button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true">Show {{ showCountEmails }}
                            <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);">
                                <i class="fa fa-chevron-down pull-right"></i>
                            </span>
                        </span>
                        <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountEmails !== 5"  @click="optionClicked(5, 'email')">Show 5</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountEmails !== 10" @click="optionClicked(10, 'email')">Show 10</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountEmails !== 20" @click="optionClicked(20, 'email')">Show 20</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountEmails !== 50" @click="optionClicked(50, 'email')">Show 50</a>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="!showOldEmails" class="empty-default">
                      <div>
                        <div style="margin: 15px 0px 25px 0px;">
                          No new emails today
                        </div>
                        <div>
                          <button class="btn btn-secondary btn-border-theme" @click="showOldEmails = true">View Previous</button>
                        </div>
                      </div>
                    </div>
                    <div v-else class="empty-default">
                      <div>
                        <div class="no-notices-block mb-3">
                          No emails
                        </div>
                      </div>
                    </div>
                  </tab>
                  <tab name="SMS" id="dashboard-tasks-tab-sms"  :suffix="smsSuffix">
                    <div v-if="(sms && sms.length > 0)" class="">
                      <sms-summary v-for="(message, si) in sms" v-bind:key="si" :sms="message" v-show="si < showCountSms" />
                      <div class="navbar-nav nav dropdown dropdown-options show inline float-right mr-0">
                          <span
                              ref="sort-dropdown"
                              class="btn waves-effect waves-light ex-options-button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true">Show {{ showCountSms }}
                              <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);">
                                  <i class="fa fa-chevron-down pull-right"></i>
                              </span>
                          </span>
                        <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountSms !== 5"  @click="optionClicked(5, 'sms')">Show 5</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountSms !== 10" @click="optionClicked(10, 'sms')">Show 10</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountSms !== 20" @click="optionClicked(20, 'sms')">Show 20</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountSms !== 50" @click="optionClicked(50, 'sms')">Show 50</a>
                        </div>
                      </div>
                    </div>
                    <div v-else class="empty-default">
                      <div>
                        <div class="mb-3 no-notices-block">
                          No SMS
                        </div>
                      </div>
                    </div>
                  </tab>
                  <tab name="Insurer" :suffix="insurerSuffix">
                    <div class="" v-if="true">
                      <insurer-summary v-for="(insurer, index) in insurers" v-bind:key="index" :insurer="insurer" v-show="index < showCountSms"></insurer-summary>
                      <div class="navbar-nav nav dropdown dropdown-options show inline float-right mr-0">
                          <span
                              ref="sort-dropdown"
                              class="btn waves-effect waves-light ex-options-button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true">Show {{ showCountSms }}
                              <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);">
                                  <i class="fa fa-chevron-down pull-right"></i>
                              </span>
                          </span>
                        <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountSms !== 5"  @click="optionClicked(5, 'sms')">Show 5</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountSms !== 10" @click="optionClicked(10, 'sms')">Show 10</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountSms !== 20" @click="optionClicked(20, 'sms')">Show 20</a>
                          <a class="dropdown-item text-primary p-1 clickable" v-if="showCountSms !== 50" @click="optionClicked(50, 'sms')">Show 50</a>
                        </div>
                      </div>
                    </div>
                    <div class="empty-default">
                      <div>
                        <div v-if="false" class="mb-3 no-notices-block">
                          No Insurer
                        </div>
                      </div>
                    </div>
                  </tab>
                  <template slot="nav-item-right">
                    <div class="tabs-nav-right-search">
                      <b-form-input v-model="filterEmail"
                                    type="text"
                                    class="search-input"
                                    placeholder="Search..."
                                    debounce="500"/>
                    </div>
                  </template>
                </tabs>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-4 p-0">
            <div class="card p-1 areaTouch">
              <div class="uppercase dashboard-header pl-1 areaTouch">
                <strong class="areaTouch">NOTICES</strong>
              </div>
              <div class="card-block dashboard-body dashboard-body-additional-block notices-block areaTouch" @touchstart="touchClose">
                <tabs ref="dashboard-tasks-tabs" :options="{ useUrlFragment: false }">
                  <tab name="Promo" id="dashboard-notices-tab-promo">
                    <div class="promo-block">
                      <div v-if="computedPromos.length == 0" class="no-notices-block" style="width: 100%;">
                        No new Promo today
                      </div>
                      <div v-else v-for="promo in computedPromos" class="card-promo areaTouch">
                        <a @contextmenu.prevent="openContextForPromo($event, promo.id)" :href="promo.link" target="_blank"  @touchstart="touchStart($event, promo.id)" @touchend="touchEnd" :class="{noLink: isNoLink}">
                          <img class="image-promo areaTouch" width="100%" :src="promo.src" alt="">
                        </a>
                      </div>
                    </div>
                  </tab>
                  <tab name="Team" id="dashboard-notices-tab-team">
                    <div class="no-notices-block">
                      No new Team today
                    </div>
                  </tab>
                  <tab name="News" id="dashboard-notices-tab-news">
                    <div class="no-notices-block">
                      No new News today
                    </div>
                  </tab>
                </tabs>
                <div>

                </div>
              </div>

              <vue-context style="height: auto !important;" :close-on-click="false"  ref="contextForPromo">
                <ul slot-scope="item">
                  <li v-if="item.data && item.data.promoId" @click="removePromo(item.data.promoId)">Remove Promo</li>
                </ul>
              </vue-context>



            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-block" style="margin-top: 15px;align-self: start; height:450px;">
<!--      <group-lists></group-lists>-->
<!--      <notifications></notifications>-->
    </div>
    <div>
      <div v-if="isShowLoader" :style="{left: leftForLoader + 'px', top: topForLoader + 'px'}" id="context-loader">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                  <circle cx="16" cy="16" r="15.9155" class="context-loader-background" />
                  <circle cx="16" cy="16" r="15.9155" :class="{'active': isShowLoader}" class="context-loader-progress" />
                </svg>
              </div>
              <ul v-if="isShowContext" :style="{left: leftForLoader + 'px', top: topForLoader + 'px', position: 'absolute'}" id="context-menu-iphone">
                <li class="areaTouch" @touchend="removePromo(selectedPromoId)">Remove Promo</li>
              </ul>
    </div>
  </div>
</template>

<script>
  import NewCard from './new-card';
  import {mapActions, mapGetters} from "vuex";
  import Axios from "axios";
  import TaskSummary from './tasks/task-summary';
  import CommentSummary from './tasks/comment-summary';
  import EmailSummary from './tasks/email-summary';
  import CardsSummary from './tasks/cards-summary';
  import _ from "lodash" ;
  import {VueContext} from 'vue-context';
  import {isMobile, isMobileOnly} from '../../deviceDetect/index';
  import SmsSummary from "@/components/dashboard/tasks/sms-summary";
  import SearchFilter from '../search-filter/search-filter-2'
  import InsurerSummary from "@/components/dashboard/tasks/insurer-summary.vue";

  export default {
    name: 'dashboard',
    components: {
      InsurerSummary,
      SmsSummary,
      NewCard,
      TaskSummary,
      CommentSummary,
      EmailSummary,
      CardsSummary,
      VueContext,
      SearchFilter
    },
    data() {
      return {
        filter: null,
        filterEmail: null,
        comments: [],
        tasks: [],
        emails: [],
        sms: [],
        insurers:[],
        showTasks: 5,
        showComments: 5,
        showEmails: 5,
        activeTab: '',
        searchFilter: '',
        isNoLink: false,
        promos: [],
        team:   [],
        news:   [],
        selectedPromoId: null,
        isMobile,
        isMobileOnly,
        isShowLoader: false,
        topForLoader: 0,
        leftForLoader: 0,
        touchStartTime: 0,
        isShowContext: false,
        leftForTouchMenu: 0,
      }
    },
    mounted() {
      this.loadData();
    },
    methods: {
      ...mapActions({
        actionLoadData: 'dashboard/loadData',
      }),
      redirectToCardEdit(itm) {
        this.$router.push('/cards/view/' + itm.value)
      },
      onEnter() {
        if (!_.isEmpty(this.additionalMenu) && !_.isEmpty(this.searchFilter)) {
          if (this.additionalMenu.length == 1) {
            this.redirectToCardEdit(this.additionalMenu[0])
          } else {
            this.$store.commit('setCardFilter', this.searchFilter);
            this.$router.push('/cards')
          }
        }
      },
      loadData(isShowNProgress = false) {
        this.setData();
        this.actionLoadData(isShowNProgress)
            .then((t) => {
              this.setData();
            })
            .catch((error) => {
              console.log(error);
            });
      },
      setData() {
        this.tasks = this.getterTasks;
        this.comments = this.getterComments;
        this.emails = this.getterEmails;
        this.sms = this.getterSms;
        this.insurers = this.getterInsurers;
      },
      openContextForPromo(event, promoId) {
        if (this.isMobile) {
          return;
        }
        this.$refs.contextForPromo.open(event, {promoId: promoId});
      },
      removePromo(promoId) {
        Axios.post('/fe/adv/notice-promo/remove',
            {
              "promoId": promoId
            }
        )
            .then(response => {
              if (response.data._status) {
                toastr.success('The Promo deleted');
                this.$store.commit('dashboard/removePromo', {promoId: promoId});
              }
            })
            .catch(error => {
              console.log(error);
            });
        this.$refs.contextForPromo.close();
        setTimeout(()=> {
          this.isShowContext = false;
        }, 2000)

      },
      touchStart(event, promoId) {
        this.selectedPromoId = promoId;

        this.touchStartTime = new Date();
        this.topForLoader = event.touches[0].pageY;
        this.leftForLoader = event.touches[0].pageX;

        console.log('start', event)
        this.isShowLoader = true;
      },
      touchEnd(event) {
        this.isShowLoader = false;

        try {
          if (event.targetTouches[0].target.parentElement.id !== 'context-loader-progress') {
              this.isShowContext = false;
          }
        } catch (err) {
            this.isShowContext = false;
        }

        let touchEndTime = new Date();
        let touchDelay = (touchEndTime - this.touchStartTime) / 700;
        if (touchDelay >= 0.7) {
          this.isNoLink = true;
          this.isShowContext = true;
        }
      },
      touchClose(event) {
        if (event.targetTouches[0].target.parentElement.parentElement.id !== 'context-menu-iphone') {
          this.isShowContext = false;
          setTimeout(()=> {
            this.isNoLink = false;
          }, 1000)

        }
      },
      optionClicked(count, type) {
        if (type === 'task') {
          this.showCountTasks = count;
          if (!this.showOldTasks) {
            this.isShowLastTasks = true;
            this.showOldTasks = true;
          }
        } else if (type === 'comment') {
          this.showCountComments = count;
          if (!this.showOldComments) {
            this.isShowLastComments = true;
            this.showOldComments = true;
          }
        } else if (type === 'email') {
          this.showCountEmails = count;
          if (!this.showOldEmails) {
            this.isShowLastEmails = true;
            this.showOldEmails = true;
          }
        } else if (type === 'sms') {
          this.showCountSms = count;
        }
      },
      isFilteredElement(filterText, query) {
        if (!query) {
          return true;
        }
        if (filterText && filterText != '') {
          return (new RegExp(query, 'iug').test(filterText));
        }
        return false;
      },
      changedTab(tab) {
        this.activeTab = tab.tab;
      },
      showMoreTasks() {
        this.showTasks += 5;
      },
      showMoreComments() {
        this.showComments += 5;
      },
      showMoreEmails() {
        this.showEmails += 5;
      },
    },
    computed: {
      ...mapGetters({
        isDisplayBoardUser: 'isDisplayBoardUser',
        isNeedUpdateDashboard: "updater/isNeedUpdateDashboard",
        isNeedUpdatePromos: 'updater/isNeedUpdatePromos',
        getShowCountTasks: "dashboard/getShowCountTasks",
        getShowCountComments: "dashboard/getShowCountComments",
        getShowCountCards: "dashboard/getShowCountCards",
        getShowCountEmails: "dashboard/getShowCountEmails",
        getShowCountSms: "dashboard/getShowCountSms",
        getShowOldTasks: "dashboard/getShowOldTasks",
        getShowOldComments: "dashboard/getShowOldComments",
        getShowOldEmails: "dashboard/getShowOldEmails",
        getIsShowLastTasks: "dashboard/getIsShowLastTasks",
        getIsShowLastComments: "dashboard/getIsShowLastComments",
        getIsShowLastEmails: "dashboard/getIsShowLastEmails",
        isShopManagerUser: 'isShopManagerUser',
        isAdministrator: 'isAdministrator',
        isCustomerService: 'isCustomerUser',
        userInfo: 'userInfo',

        getterEmails: 'dashboard/getEmails',
        getterSms: 'dashboard/getSms',
        getterInsurers: 'dashboard/getInsurers',
        getterTasks: 'dashboard/getTasks',
        getterComments: 'dashboard/getComments',
        getterCards: 'card/getCardsAssignedToMe',
        getterPromos: 'dashboard/getPromos',
        getterTeam: 'dashboard/getTeam',
        getterNews: 'dashboard/getNews',
        cardsAll: 'card/getAllCards',
      }),
      additionalMenu() {
        if (_.isEmpty(this.searchFilter)) {
          return []
        }
        let data = _.filter(this.cardsAll, (r) => {
          return _.startsWith(_.trim(_.toLower(r.card_number)), _.trim(_.toLower(this.searchFilter))) ||
              _.startsWith(_.trim(_.toLower(r.rego_number)), _.trim(_.toLower(this.searchFilter))) ||
              _.includes(_.trim(_.toLower(r.customer_name)), _.trim(_.toLower(this.searchFilter)))
        })
        let result = [];
        _.forEach(data, (itm) => {
          let label = itm.card_number
          if (itm.rego_number) {
            label +=  ' - '
          }

          if (itm.rego_number) {
            label += itm.rego_number + ' '
          }

          result.push({
            value: itm.card_id,
            label: label,
            estimateNumber: itm.card_number,
            regoNumber: itm.rego_number,
            customerName: itm.customer_name,
            insurerName: itm.insurer_name,
            make: itm.make,
            model: itm.model,
          })
        })
        result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
        return result
      },
      smsSuffix() {
        let notViewedSMS = _.filter(this.sms, (s) => {
          return !s.isViewed
        });
        if (notViewedSMS.length) {
          return '<span class="tag tag-info">' + notViewedSMS.length + '</span>'
        }
        return  ''
      },
      insurerSuffix() {
        let notViewedInsurers = _.filter(this.insurers, (s) => {
          return !s.isViewed
        });
        if (notViewedInsurers.length) {
          return '<span class="tag tag-info">' + notViewedInsurers.length + '</span>'
        }
        return  ''
      },
      commentSuffix() {
        let notViewedComments = _.filter(this.comments, (s) => {
          return !s.isViewed
        });
        if (notViewedComments.length) {
          return '<span class="tag tag-info">' + notViewedComments.length + '</span>'
        }
        return  ''
      },
      tasksSuffix() {
        let notViewedTasks = _.filter(this.tasks, (s) => {
          return !s.isViewed
        });
        if (notViewedTasks.length) {
          return '<span class="tag tag-info">' + notViewedTasks.length + '</span>'
        }
        return  ''
      },
      emailsSuffix() {
        let notViewedEmails = _.filter(this.emails, (s) => {
          return !s.isViewed
        });
        if (notViewedEmails.length) {
          return '<span class="tag tag-info">' + notViewedEmails.length + '</span>'
        }
        return  ''
      },
      cardsSuffix() {
        let notViewedCards = _.filter(this.getterCards, (s) => {
          return !s.isViewed
        });
        if (notViewedCards.length) {
          return '<span class="tag tag-info">' + notViewedCards.length + '</span>'
        }
        return  ''
      },
      computedTeam() {
        return this.getterTeam;
      },
      computedPromos() {
        return this.getterPromos;
      },
      computedNews() {
        return this.getterNews;
      },
      isShowEmails() {
        if (_.isEmpty(this.userInfo)) {
          return true
        } else {
          return this.isShopManagerUser || this.isAdministrator || this.isCustomerService;
        }
      },
      computedTasks() {
        if (!this.filter) {
          return this.tasks;
        }
        let data = [];
        let vm = this;
        data = _.filter(this.tasks, function (task) {
          return (vm.isFilteredElement(task.notesText, vm.filter)
              || vm.isFilteredElement(task.name, vm.filter)
              || vm.isFilteredElement(task.cardNumber, vm.filter)
              || vm.isFilteredElement(task.estimateNumber, vm.filter)
              || vm.isFilteredElement(task.createdById, vm.filter));
        });
        return data;
      },
      computedComments() {
        if (!this.filter) {
          return this.comments;
        }
        let data = [];
        let vm = this;
        data = _.filter(this.comments, function (comment) {
          return (vm.isFilteredElement(comment.text, vm.filter)
              || vm.isFilteredElement(comment.task.name, vm.filter)
              || vm.isFilteredElement(comment.task.cardNumber, vm.filter)
              || vm.isFilteredElement(comment.task.estimateNumber, vm.filter)
              || vm.isFilteredElement(comment.createdById, vm.filter));
        });
        return data;
      },
      computedCards() {
        if (!this.filter) {
          return this.getterCards;
        }
        let data = [];
        let vm = this;
        data = _.filter(this.getterCards, function (card) {
          return (vm.isFilteredElement(card.card_number, vm.filter)
              || vm.isFilteredElement(card.customer_name, vm.filter)
              || vm.isFilteredElement(card.rego_number, vm.filter)
              || vm.isFilteredElement(card.make, vm.filter)
              || vm.isFilteredElement(card.model, vm.filter)
              || vm.isFilteredElement(card.colour, vm.filter)
              || vm.isFilteredElement(card.insurance, vm.filter)
              || vm.isFilteredElement(card.created_on, vm.filter)
              || vm.isFilteredElement(card.assignedTo.name, vm.filter)
              || vm.isFilteredElement(card.priorty, vm.filter)
              || vm.isFilteredElement(card.status_name, vm.filter)
              || vm.isFilteredElement(card.claim_number, vm.filter)
              || vm.isFilteredElement(card.filters, vm.filter)
              || vm.isFilteredElement(card.partNumber, vm.filter)
              || vm.isFilteredElement(card.driverName, vm.filter)
          )
        });
        return data;
      },
      computedEmails() {
        if (!this.filterEmail) {
          return this.emails;
        }
        let data = [];
        let vm = this;
        data = _.filter(this.emails, function (email) {
          return (vm.isFilteredElement(email.from, vm.filterEmail)
              || vm.isFilteredElement(email.recipient, vm.filterEmail)
              || vm.isFilteredElement(email.subject, vm.filterEmail));
        });
        return data;
      },
      isShowLastTasks: {
        get() {
          return this.getIsShowLastTasks;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setIsShowLastTasks', newVal);
        }
      },
      isShowLastComments: {
        get() {
          return this.getIsShowLastComments;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setIsShowLastComments', newVal);
        }
      },
      isShowLastEmails: {
        get() {
          return this.getIsShowLastEmails;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setIsShowLastEmails', newVal);
        }
      },
      showCountTasks: {
        get() {
          return this.getShowCountTasks;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setShowCountTasks', newVal);
        }
      },
      showCountComments: {
        get() {
          return this.getShowCountComments;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setShowCountComments', newVal);
        }
      },
      showCountCards: {
        get() {
          return this.getShowCountCards;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setShowCountCards', newVal);
        }
      },
      showCountEmails: {
        get() {
          return this.getShowCountEmails;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setShowCountEmails', newVal);
        }
      },
      showCountSms: {
        get() {
          return this.getShowCountSms;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setShowCountSms', newVal);
        }
      },
      showOldTasks: {
        get() {
          return this.getShowOldTasks;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setShowOldTasks', newVal);
          this.loadData(true);
        }
      },
      showOldComments: {
        get() {
          return this.getShowOldComments;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setShowOldComments', newVal);
          this.loadData(true);
        }
      },
      showOldEmails: {
        get() {
          return this.getShowOldEmails;
        },
        set(newVal) {
          this.$store.dispatch('dashboard/setShowOldEmails', newVal);
          this.loadData(true);
        }
      },
      headerForTask() {
        if (this.activeTab) {
          console.log('2', this.activeTab.computedId);
        }
        if (this.activeTab && this.activeTab.computedId && this.activeTab.computedId == "dashboard-tasks-tab-comments") {
          if (this.isShowLastComments) {
            return 'Last Comments';
          } else if (this.getShowOldComments) {
            return 'Previous Comments';
          } else {
            return 'Today\'s New Comments';
          }
        }
        if (this.activeTab && this.activeTab.computedId && this.activeTab.computedId == "dashboard-tasks-tab-cards") {
          return 'Previous Cards Assigned';
        }
        if (this.isShowLastTasks) {
          return 'Last Tasks Assigned'
        } else if (this.getShowOldTasks) {
          return 'Previous Tasks Assigned';
        } else {
          return 'Today\'s New Tasks Assigned';
        }
      },
      headerForEmail() {
        if (this.isShowLastEmails) {
          return 'Last Incoming Messages'
        } else if (this.getShowOldEmails) {
          return 'Incoming Messages';
        }
        return 'Today\'s Incoming Messages';
      },
      showMoreTasksCount() {
        let d = this.tasks.length - this.showTasks;
        if (d > 5) {
          return 5;
        }
        if (d < 0) {
          return 0;
        }
        return d;
      },
      showMoreCommentsCount() {
        let d = this.comments.length - this.showComments;
        if (d > 5) {
          return 5;
        }
        if (d < 0) {
          return 0;
        }
        return d;
      },
      showMoreEmailsCount() {
        let d = this.emails.length - this.showEmails;
        if (d > 5) {
          return 5;
        }
        if (d < 0) {
          return 0;
        }
        return d;
      }
    },
    created() {
      if (this.isDisplayBoardUser){
        this.$router.push('/boards/vehicle-in')
      }
    },
    watch: {
      isNeedUpdatePromos: {
        handler() {
          this.$store.dispatch('dashboard/loadNotices');
          this.$store.dispatch('updater/resetPromos');
        },
        immediate: true,
      },
      isNeedUpdateDashboard(newVal) {
        if (!newVal) return;
        this.loadData();
        this.$store.dispatch('updater/resetDashboard');
      }
    },
  }
</script>

<style scoped>
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__input{
      font-size: 13px !important;
      font-weight: 800;
      color: black;
    }
    .tabs-nav-right-search .search-filter >>> .custom-dropdown{
      top: 37px;
      left: -85px;
      padding-top: 2px;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__box{
      border: 3px solid #5e77fd;
      border-radius: 7px;
    }
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .search-filter >>> input::placeholder{
      color: #bbbbc2;
    }
    .search-filter >>> .search-filter__box__focused{
      border: 3px solid rgba(94, 121, 255, 0.5) !important;
      box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
    }
    .search-filter >>> .search-filter__li--input{
      border-bottom: 0;
    }
    .search-filter >>> .search-filter__li{
      border-bottom: 0 !important;
    }
    .tabs-nav-right-search-font-weight >>> input{
      font-weight: 700;
    }
    .tabs-nav-right-search-font-weight >>> input::placeholder{
      font-weight: 600;
    }

    .no-notices-block {
      text-align: center;
      margin-top: 15px;
    }
    .d-flex {
      display: flex;
    }
    .flex-row {
      flex-direction: row;
    }
    .flex-column {
      flex-direction: column;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .work-center-main {
      font-family: "Nunito Sans";
      font-size: 13px;
      color: #1C1F39;
      background-color: #fff;
      padding: 15px;
    }
    .right-block {
      display: flex;
      justify-content: flex-start;
      /* flex-direction: row; */
      flex-flow: row wrap;
      width: 100%;
    }
</style>

<style scoped>
    .promo-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .card-promo {
      width: 100%;
      margin-bottom: 10px;
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }

    @media screen and (min-width: 1150px) {
      .card-promo {
        width: 45%;
        margin: 0 5px 10px 5px;
      }
    }

    @media screen and (max-width: 991px) and (min-width: 540px) {
      .card-promo {
        width: 30%;
        margin: 0 5px 10px 5px;
      }
    }

    @media screen and (max-width: 539px) and (min-width: 300px) {
      .card-promo {
        width: 45%;
        margin: 0 5px 10px 5px;
      }
    }

    .areaTouch {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    #context-loader {
      position: absolute;
      width: 84px;
      height: 84px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    #context-loader svg {
      width: 100%;
      height: 100%;
      transform: rotate(-90deg);
    }

    #context-loader svg .context-loader-background {
      fill: none;
      stroke: #e2eff0;
      stroke-width: 1.8;
    }

    #context-loader .context-loader-progress {
      fill: none;
      stroke: #1b1e3812;
      stroke: #5e79ff;
      stroke-dasharray: 100 100;
      stroke-dashoffset: 100;
      stroke-linecap: round;
      stroke-width: 1.8;
      transition: all 5s ease-in-out;
    }

    .context-loader-progress.active {
      animation: dashOffset 1s forwards;
    }

    @keyframes dashOffset {
      from {
        stroke-dashoffset: 100;
      }

      to {
        stroke-dashoffset: 0;
      }
    }

    #context-menu-iphone {
      position: absolute;
      background: #fff;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
      white-space: nowrap;
      list-style: none;
      margin: 0;
      padding: 0;
      font-family: 'Nunito Sans', sans-serif;
      color: #373A3C;
      font-size: 11px !important;
      line-height: 15px !important;
      font-weight: bold !important;
      border: 1px solid #bdbdbd;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
      width: 100px;
      border-radius: 3px;
      z-index: 100;
    }

    #context-menu-iphone li {
      display: block;
      text-decoration: none;
      color: #373A3C;
      padding: 15px 10px 15px 10px !important;
      height: 31px;
      border-radius: 3px 3px 0 0;
      text-align: left;
    }

    #context-menu-iphone li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
    }

    #context-menu-iphone li:hover {
      background-color: rgba(94, 121, 255, 0.1) !important;
      color: #5E79FF !important;
      font-weight: bold;
    }

    .icon-right-touch {
      transform: translate(100%, 0) !important;
      font-size: 21px !important;
      position: absolute;
      right: 25px;
    }

    .touch-block {
      margin: 15px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

</style>

<style scoped>
    .emails-block:hover {
      cursor: pointer;
    }
    .uppercase {
      text-transform: uppercase;
    }

    .dashboard-col {
      padding: 0;
    }

    @media (min-width: 576px) {
      .dashboard-col:first-child {
        margin-left: 15px;
      }

      .dashboard-col:last-child {
        margin-right: 15px;
      }
    }

    @media (max-width: 575.98px) {
      .dashboard-col ::v-deep .tab-header.rs-btn-group {
        /* display: none; */
      }
    }
    .dashboard-body-additional-block >>> ul .tabs-component-tab{
      border-bottom: 5px solid #e1e2e5 !important;
    }
    .today-block >>> ul .tabs-component-tab{
      width: 33%;
    }
    .message-block >>> ul .tabs-component-tab{
      width: 50%;
    }
    .notices-block >>> ul .tabs-component-tab{
      width: 33%;
    }
    .dashboard-col .dashboard-body {
      padding-left: 15px;
      padding-right: 15px;
      background-color: rgba(241, 241, 247, 0.618);
      min-height: 400px;
      background-color: rgba(27, 30, 56, 0.1) !important;
    }

    .dashboard-col ::v-deep .tabs-component {
      position: inherit;
      margin-bottom: 0.75rem;
    }

    .dashboard-col ::v-deep .tabs-component .tab-header {
      height: 50px;
      flex-flow: row nowrap;
    }

    .dashboard-col ::v-deep .nav-tabs-2 .nav-link {
      height: 39px;
    }

    .dashboard-col ::v-deep .dashboard-row-element {
      background-color: #FFFFFF;
      /* background-color: #efefee; */ /* for muted */
    }

    .dashboard-col .dashboard-body ::v-deep .tab-content {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .empty-default {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }

    /* Dashboard Search Input Style */
    .dashboard-body ::v-deep .tabs-component {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .dashboard-body ::v-deep .tabs-component::after {
      content: unset;
    }

    .dashboard-body ::v-deep .tabs-component .nav-tabs {
      /* flex: 0 0 auto; */
      /* background-color: yellow; */
    }

    .dashboard-body ::v-deep div.tab-header.rs-btn-group {
      flex: 1 1 290px;
    }

    .dashboard-body ::v-deep div.tabs-nav-right-search {
      min-width: unset !important;
      width: 100%;
    }

    .dashboard-body ::v-deep div.tabs-nav-right-search {
      height: 50px;
      display: flex;
      align-items: center;
    }
    /* End - Dashboard Search Input Style */

    @media (max-width: 991px) {
      .card {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .noLink {
      pointer-events: none;
    }

    .btn-border-theme {
      border: 1px solid #5E79FF;
    }

    .btn-border-theme:hover {
      border: 1px solid #5E79FF;
      background-color: #fff;
    }
</style>

<style>
    .show-count-dropdown .btn {
      border: 1px solid #00B050 !important;
      background-color: #FFFFFF !important;
      color: #333333 !important;
    }

    .dashboard-header {
      padding-bottom: 5px;
    }

    .dashboard-body {
      padding-left: 15px;
      padding-right: 15px;
      min-height: 400px;
      background-color: rgba(27, 30, 56, 0.1);
    }

    .view-tasks-block {
      align-self: center;
    }

    @media (max-width: 991px) {
      .padding-md-block {
        padding-left: 1rem;
      }
    }
    .dashboard-body-additional-block .dropdown-options,
    .dashboard-body-additional-block .ex-options-button {
      width: 120px !important;
    }

    .dashboard-body-additional-block .tabs-component ul {
      display: flex;
      flex-wrap: wrap;
    }

    .dashboard-body-additional-block .tabs-component {
      margin-top: 10px;
      background-color: white;
      padding: 0 !important;
      border: 1px solid rgba(28, 31, 57, 0.25);
    }

    .dashboard-body-additional-block .tabs-component::after {
      display: none;
    }

    .dashboard-body-additional-block .tab-header {
      width: 100%;
    }

    .dashboard-body-additional-block .tabs-nav-right-search {
      width: 100%;
      margin: 15px 0 !important;
    }

    .dashboard-body-additional-block .tab-content {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .line-divider-dashboard {
      width: calc(100% + 30px);
      height: 10px;
      position: relative;
      background: #e3e4e7;
      left: -15px;

    }
</style>
